<template>
  <div class="content-box">
    <p style="text-align: center"><strong>用户隐私政策</strong></p>
    <p style="text-align: right">
      <strong>更新生效日期：2024年</strong><strong>07</strong><strong>月</strong
      ><strong>19</strong><strong>日</strong>
    </p>
    <p><strong>【阅读提示】</strong></p>
    <p>
      <strong>1、欢迎您使用联动优势电子商务有限公司（</strong
      ><strong>注册地位于：</strong
      ><strong>山东省青岛市崂山区海尔路182-8号半岛国际大厦19楼</strong
      ><strong>，</strong
      ><strong
        >以下称&ldquo;我们&rdquo;）提供的相关服务！我们尊重并保护用户隐私，本政策包含了我们收集、存储、保护、使用您的个人信息的条款，请您完整地阅读本政策，以帮助您了解维护自己隐私权的方式。在您享用我们提供服务的过程中，由于服务的需要我们需要使用您的个人信息。我们深知保护个人信息责任重大，因此一直致力于保护用户的信息，并制定了本《用户隐私政策》（以下简称&ldquo;本政策&rdquo;）。</strong
      >
    </p>
    <p>
      <strong
        >2、为了使您充分理解本政策，本政策中与您的权益存在或可能存在重大关系的条款，我们已采用粗体字进行标注提示您注意，请您务必仔细查看。我们收集、使用您的信息是出于遵守国家法律法规的规定以及向您提供服务及提升服务质量的目的（包括支持我们开发新产品或完善已有产品功能，为您和其他用户提供更为优质的服务），若您向我们提供了本政策中列明的信息，您可享受更多、更便捷的客户服务，使我们可以更好地保护您的账户安全等。</strong
      >
    </p>
    <p>
      <strong
        >3、请您审慎阅读并选择接受或不接受本政策（未成年人应在监护人陪同下阅读），如您访问我们公司网站或移动设备客户端、POS终端、微信公众号并进行注册/登陆，或通过页面点击确认，或使用我们产品或接受我们服务，即视为您与我们已达成一致并同意接受本授权政策的所有条款，对本授权政策已充分理解，并完全清楚授权内容所产生的相关法律后果。我们将按照本政策收集、存储、使用您的信息。</strong
      >
    </p>
    <p>
      <strong
        >4、本政策所称&ldquo;我们服务&rdquo;，包括但不限于您访问我们网站及其相关网站、或是下载、安装、使用我们各类客户端、或使用我们POS终端机具等产品，或使用我们提供的任一服务。本政策中的&ldquo;身份要素&rdquo;是指：我们用于识别用户身份的信息要素，例如：您在我们登录名、密码、短信校验码、电话号码、手机号码、证件号码及生物识别信息（例如：指纹信息、脸部信息）。</strong
      >
    </p>
    <p>
      <strong
        >5、我们将努力采取合理的安全措施来保护您的个人信息，我们将采用行业内通行的方式及尽最大的商业努力来保护您个人敏感信息的安全。</strong
      >
    </p>
    <p><strong>本隐私政策将帮助您了解以下内容：</strong></p>
    <p><strong>一、我们如何收集和使用个人信息</strong></p>
    <p><strong>二、我们如何存储、保护个人信息</strong></p>
    <p><strong>三、您的权利</strong></p>
    <p><strong>四、我们如何共享、转让、公开披露个人信息</strong></p>
    <p><strong>五、未成年人使用条款</strong></p>
    <p><strong>六、隐私政策的修订和通知</strong></p>
    <p><strong>七、如何与我们联系</strong></p>
    <p><strong>一、我们如何收集和使用个人信息</strong></p>
    <p>
      <strong
        >我们将根据合法、正当、必要、&ldquo;最小化&rdquo;原则收集信息。我们收集或请您提供的信息将用于：</strong
      >
    </p>
    <p><strong>1.保障产品的基础正常运行；</strong></p>
    <p><strong>2.实现各项功能和服务；</strong></p>
    <p><strong>3.优化、改善产品和服务；</strong></p>
    <p><strong>4.保障产品、服务以及用户使用安全；</strong></p>
    <p><strong>5.遵循法律法规与国家标准的规定。</strong></p>
    <p><strong>（一）个人信息授权、使用</strong></p>
    <p>
      <strong>1、依据法律法规及监管规定履行反洗钱义务及进行实名制管理</strong>
    </p>
    <p>
      （1）&nbsp;在您注册账户或使用服务时，您需提供手机号码或者电子邮箱作为账户登录名。<strong>您需主动提供并收集您的身份基本信息，包括姓名、联系方式（电话）以及有效身份证件的种类、号码和有效期限、银行账号、电子邮箱</strong>，同时您授权并同意，我们将您的信息用于身份验证、客户服务、安全防范、诈骗监测、预防或禁止非法活动、存档和备份用途，确保我们向您提供的产品和服务的安全性，比较信息的准确性，并与第三方进行验证，例如，将您向&ldquo;我们&rdquo;提交的身份信息与合法的身份验证的服务机构进行交叉验证，以核实您身份的真实性、准确性。如您不提供前述信息，可能无法注册账户或无法使用相应服务。如您不提供前述信息，可能无法使用需要通过交叉验证后方可使用的部分服务，但不影响您使用我们提供的其他服务；
    </p>
    <p>
      （2）我们需根据《支付机构反洗钱和反恐怖融资管理办法》等反洗钱相关法律法规及监管要求，提示您适时提供<strong>有效身份证件的彩色影印件或照片</strong>以供我们核对并留存；如您不提供前述彩色影印件、照片或不同意我们留存，可能无法使用与监管要求相关的部分服务，但不影响您使用我们提供的其他服务。
    </p>
    <p>
      2、为便于您查询交易状态或历史记录，也为了遵守法律法规的规定，&ldquo;我们&rdquo;会保存您使用我们服务产生的<strong>交易信息</strong>，并严格按照法律法规的规定对这些信息进行妥善保管。
    </p>
    <p>
      3、为了充分保护您的账户安全，当您访问我们网站及其相关网站，或我们移动设备客户端、POS终端、微信公众号，或使用我们提供的支付相关功能/服务时，我们可能会记录您操作的相关信息，<strong>包括您的硬件序列号、设备mac地址、唯一设备识别码（deviceId、androidId、imei）、软件列表、软件使用记录、IP地址、登录密码、支付密码、数字证书</strong>，这些信息可帮助&ldquo;我们&rdquo;更好地识别您的身份以及保护您的账户安全，例如您的账户在可疑操作环境下登录，我们系统可能监控到您的账户风险，采取一些措施避免您的资金损失。
    </p>
    <p>
      4、您授权&ldquo;我们&rdquo;向行政机关、事业单位、司法机关、监管部门、中国互联网金融协会等行业自律组织查询、打印、留存客户信息；
    </p>
    <p>
      5、除上述信息外，&ldquo;我们&rdquo;还可能为了提供服务及改进服务质量的合理需要而收集您的其他信息，包括您与&ldquo;我们&rdquo;的客户服务团队联系时您提供的相关信息；您参与问卷调查时向&ldquo;我们&rdquo;发送的问卷答复信息；以及您与&ldquo;我们&rdquo;互动时&ldquo;我们&rdquo;收集的相关信息。与此同时，为提高您使用我们提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，&ldquo;我们&rdquo;可能会通过了解一些您的网络使用习惯、您常用的软件信息等手段来判断您账户的风险，并可能会记录一些&ldquo;我们&rdquo;认为有风险的URL。
    </p>
    <p>
      6、为使您知晓自己使用我们服务的情况或了解我们的服务，你您授权&ldquo;我们&rdquo;向您发送服务状态的通知、营销活动及其他商业性电子信息。
    </p>
    <p>
      7、为了使您了解我们产品的具体情况，您同意我们向您发送营销活动通知、商业性电子信息以及提供与您相关的广告以替代普遍投放的广告；<strong
        >如您不希望接收该类等信息，您</strong
      ><strong>可以选择拒绝接收该类信息</strong>。
    </p>
    <p>8、您授权&ldquo;我们&rdquo;可邀请您参与有关我们产品和服务的调查。</p>
    <p>9、经您许可的其他用途。</p>
    <p>
      <strong>10、您理解并同意，您注册使用我们时，即授权&ldquo;</strong
      ><strong>我们</strong><strong>&rdquo;</strong
      ><strong>根据您所提供的各项信息及</strong><strong>&ldquo;</strong
      ><strong>我们</strong><strong>&rdquo;</strong
      ><strong>获得的信息评定您在</strong><strong>&ldquo;</strong
      ><strong>我们</strong><strong>&rdquo;</strong
      ><strong>的商户等级，或决定是否审核通过您的服务申请。</strong>
    </p>
    <p><strong>(二) 收集、使用个人信息目的变更的处理</strong></p>
    <p>
      请您了解，我们向您提供的功能和服务是不断更新和发展的，当新功能或服务与服务场景相关时，收集与使用的个人信息属于与原目的具有直接或合理关联。在与原目的无直接或合理关联的场景下，我们收集、使用您的个人信息，我们会通过页面提示、交互流程、网站公告等方式另行向您说明信息收集的内容、范围和目的，以征得您的同意。
    </p>
    <p><strong>（三）依法豁免征得同意收集和使用的个人信息</strong></p>
    <p>
      <strong
        >请您理解，根据相关法律法规及国家标准，以下情形中，我们收集、使用您的相关个人信息无需征求您的授权同意：</strong
      >
    </p>
    <p>
      <strong
        >(1)
        与国家安全、国防安全等国家利益直接相关的；与公共安全、公共卫生、公众知情等重大公共利益直接相关的；</strong
      >
    </p>
    <p><strong>(2) 与犯罪侦查、起诉、审判和判决执行等直接相关的；</strong></p>
    <p>
      <strong
        >(3)
        出于维护您或其他个人的生命、财产、声誉等重大合法权益但又很难得到本人同意的；</strong
      >
    </p>
    <p><strong>(4) 所收集的个人信息是您自行向社会公众公开的；</strong></p>
    <p>
      <strong
        >(5)
        从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</strong
      >
    </p>
    <p><strong>(6) 根据您要求签订或履行合同所必需的；</strong></p>
    <p>
      <strong
        >(7)
        用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</strong
      >
    </p>
    <p><strong>(8) 为开展合法的新闻报道所必需的；</strong></p>
    <p>
      <strong
        >(9)
        出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</strong
      >
    </p>
    <p>
      <strong
        >(10)
        司法机关、政府监管部门、清算组织、行业自律协会要求提供的情形；</strong
      >
    </p>
    <p><strong>(11) 法律法规规定的其他情形。</strong></p>
    <p><strong>（四）Cookie、Beacon、Proxy的使用</strong></p>
    <p>
      1、为使您获得更轻松的访问体验，您访问我们的网站、客户端或使用我们提供的服务/功能时，我可会通时小型数据文件识别你的身份，这么做可帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全状态。这些数据文件可能是Cookie、FashCookie，您的浏览器或关联应用程序提供的其他本地存储（以下简称&ldquo;Cookie&rdquo;）。<strong
        >请您理解，我们的某些服务只能通过使用Cookie才可得到实现</strong
      ><strong>。</strong
      ><strong>如的浏览或浏览器附加服务允许，您可以修改对C</strong
      ><strong>oo</strong><strong>kie的接受</strong><strong>程度</strong
      ><strong>或者拒绝Cookie，多数浏览器工具条中的</strong
      ><strong>&ldquo;</strong><strong>帮助</strong><strong>&rdquo;</strong
      ><strong>部分</strong><strong>会</strong
      ><strong>告诉您怎样防止您的浏览器接受新的C</strong><strong>o</strong
      ><strong>okie,怎样让您的浏览器在您</strong><strong>收</strong
      ><strong>到</strong><strong>一条新</strong
      ><strong>Cookie时通知您或者怎样</strong><strong>彻底</strong
      ><strong>关闭</strong><strong>C</strong
      ><strong
        >ookie。此外，您可以通过政变浏览器附加程序的设置，或通过访问提供商的网页，来关闭或</strong
      ><strong>删</strong><strong>除浏览器附加程序使用的F</strong
      ><strong>l</strong
      ><strong
        >ashCookie及类似数据。但这一举动在某些情况下可能会影响您安全访问</strong
      ><strong>我们的</strong><strong>网站</strong><strong>/客户端</strong
      ><strong>和使用</strong><strong>我们</strong><strong>提供的服务</strong
      ><strong>/功能</strong><strong>。</strong>
    </p>
    <p>
      <strong>2</strong
      ><strong>、我们网站上还可能包含一些电子图像（以下简称&ldquo;</strong
      ><strong>网络Beac</strong><strong>o</strong><strong>n</strong
      ><strong>&rdquo;），</strong><strong>使用网络Beac</strong
      ><strong>o</strong><strong>n可以</strong
      ><strong>帮助网站计算浏览网页的用户或访问某些Cookie，</strong
      ><strong>我们会通过网Beac</strong><strong>o</strong
      ><strong>n收集您</strong
      ><strong>浏览网页活动的信息。</strong
      >&nbsp;(您访问的页面地址、您先前访问的援引页面的位置、您的浏览环境以及显示设定)。
    </p>
    <p>
      <strong>3、如您通过我们的网站或</strong><strong>APP，使用了由第</strong
      ><strong>三</strong><strong>方而非</strong><strong>我们</strong
      ><strong>提供的服务</strong><strong>/功能</strong
      ><strong
        >时，为尽力确保您的账号安全，使您获得更安全的访问体验，我们可能会使用专用的网络协议及代理技术(以下简称</strong
      ><strong>&ldquo;</strong><strong>Prox</strong><strong>y</strong
      ><strong>技术</strong><strong>&rdquo;)</strong><strong>。使用Prox</strong
      ><strong>y</strong><strong>技术，可以帮助您识别</strong><strong>到</strong
      ><strong>我们已知的高风险站点，减少由此引起的钓鱼、</strong
      ><strong>账号</strong><strong>泄露等风险，</strong><strong>同时</strong
      ><strong>更有利于保您和第三方的共同权益，阻止不法分子</strong
      ><strong>篡</strong><strong>改您和您希望访问的第三方</strong
      ><strong>之间正常服务内容，包括</strong
      ><strong>:不安全路由器、非法基站等引起的广告注入、非法内容</strong
      ><strong>篡</strong><strong>改</strong><strong>。</strong
      ><strong>在此过程中，我们也可能会获得和保存您设备的</strong
      ><strong>I</strong><strong>P地址、硬件</strong><strong>I</strong
      ><strong>D以及您访问的页面位置</strong><strong>。</strong>
    </p>
    <p><strong>（五）有关第三方责任的声明</strong></p>
    <p>
      <strong
        >1、请您注意，您的交易向对方、您的交易相方、您访问的第三方网站经营者、通过本客户端所接入</strong
      ><strong>的第三方服务和由</strong><strong>我们</strong
      ><strong
        >接收您的个人信息的第三方可能有自己的隐私权保护政策;当您查看</strong
      ><strong>使用</strong><strong>第</strong><strong>三</strong
      ><strong>方创建的网页或使用第</strong><strong>三</strong
      ><strong>方开发的应用程序</strong><strong>/功能服务</strong
      ><strong
        >时，这些第三方可能会放置他们自己的Cookie或网络Beaon,这ookie或网络Beacon不受我们的控制，且它们的使用不受</strong
      ><strong>本</strong
      ><strong
        >政策的约束，我们会努力去要求这些主体对您的个人信息采取保护措施，建议您与他们联系以获得关于他们的隐私权政策的详细情况</strong
      ><strong>。</strong
      ><strong
        >如您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，</strong
      ><strong>建议您终止相关操作以保护您的合法权益。</strong>
    </p>
    <p>
      <strong
        >2、您知晓并同意，本客户端中部分服务/功能需要由第三方共同为您提供（例如：通知推送、定位功能），为此，本客户端内会嵌入第三方的软件工具开发包（以下简称&ldquo;SDK&rdquo;）或其他类似的应用程序我们会对SDK或其他类似的应用程序进行严格的安全监测，并要求第三方采取严格的数据保护措施，切实保障您的合法权益。</strong
      >
    </p>
    <p>
      <strong
        >3、本客户端相关第三方SDK见下表，您在此知晓并同意，当您使用第三方相关服务/功能时，第三方将基于该服务/功能而收集您的相关信息；
      </strong>
    </p>
    <div><strong>(1)友盟统计分析SDK；</strong></div>
    <div>目的：进行APP运营统计与分析；</div>
    <div>运营方：友盟同欣（北京）科技有限公司；</div>
    <div>
      收集个人信息类型：设备信息（IMEI/MAC/Android
      ID/IDFA/OAID/OpenUDID/GUID/SIM卡IMSI/ICCID）、位置信息、网络信息。
    </div>
    <div>
      隐私权政策链接：友盟同欣（北京）科技有限公司<a
        href="https://www.umeng.com/page/policy"
        ><u>https://www.umeng.com/page/policy</u></a
      >。
    </div>
    <div style="margin-top: 20px"><strong>(2)友盟消息推送SDK；</strong></div>
    <div>SDK服务商：友盟同欣（北京）科技有限公司；</div>
    <div>
      收集个人信息类型：设备信息（IMEI/MAC/Android
      ID/IDFA/OAID/OpenUDID/GUID/SIM卡IMSI/ICCID）、位置信息、网络信息。
    </div>
    <div>
      隐私权政策链接：友盟同欣（北京）科技有限公司<a
        href="https://www.umeng.com/page/policy"
        ><u>https://www.umeng.com/page/policy</u></a
      >。
    </div>
    <div style="margin-top: 20px"><strong>(3)友盟SDK；</strong></div>
    <div>服务类型：应用性能监控平台U-APM；</div>
    <div>运营方：友盟同欣（北京）科技有限公司；</div>
    <div>
      收集个人信息类型：个人信息字段采集：我们采集设备信息（IMEI/MAC/Android
      ID/OAID/IDFA/OpenUDID/GUID/SIM卡IMSI/ICCID等），用于唯一标识设备，以便提供设备应用性能监控服务；通过采集位置信息提供反作弊服务，剔除作弊设备，排查应用性能崩溃原因。
    </div>
    <div style="margin-top: 20px"><strong>(4)七牛云对象存储API；</strong></div>
    <div>场景：用户实名认证，添加用户个人银行卡；</div>
    <div>
      用途：用于用户实名认证，身份证、银行卡图片存储，意见反馈图片存储；
    </div>
    <div>
      个人信息字段：涉及个人信息：身份证照片、银行卡照片、用户上传的反馈图片等；
    </div>
    <div>机构/隐私政策：上海七牛信息技术有限公司，</div>
    <div>
      <a href="https://www.qiniu.com/en/privacy-right"
        ><u>https://www.qiniu.com/en/privacy-right</u></a
      >。
    </div>

    <div style="margin-top: 20px">
      <strong>(5)腾讯云慧眼人脸核身 SDK；</strong>
    </div>
    <div>提供方：腾讯云计算（北京）有限责任公司；</div>
    <div>处理目的：用于实人实名认证；</div>
    <div>
      处理方式：（1）对人脸视频进行活体检测，将姓名、身份证号、人脸图片与&ldquo;互联网+可信身份认证平台&rdquo;等核验机构存储的信息进行比对核验，得出一致或不一致的结果，并返回认证结果给开发者。
    </div>
    <div>
      （2）采集设备信息并进行去标识化处理后，用于检测认证设备实时风险，得到风险等级用于认证结果的判断，增强版还会将认证结果返回开发者。
    </div>
    <div>
      处理个人信息类型：身份证照片或者姓名和身份证号码等身份信息、人脸视频和照片和设备信息（不同版本收集的设备信息有所不同）：
    </div>
    <div>
      Android 版将收集内核版本、CPU 信息、机型、系统版本、设备制造商、运营商。
    </div>
    <div>IOS 版将收集 GUID、蜂窝网络 IP、WIFI IP。</div>
    <div>
      申请的权限：摄像头、网络、读取存储权限、麦克风（仅 SDK、H5 需要
      ，其中麦克风仅基础版需要）；
    </div>
    <div>
      隐私政策地址：<a
        href="https://privacy.qq.com/document/preview/4661fcc5ceb04a4f878a5874c86b34a2"
        ><u
          >https://privacy.qq.com/document/preview/4661fcc5ceb04a4f878a5874c86b34a2</u
        ></a
      >。
    </div>

    <div style="margin-top: 20px">
      <strong>(6)腾讯优图Faceln人脸核身SDK；</strong>
    </div>
    <div>提供方的公司名称：深圳市腾讯计算机系统有限公司；</div>
    <div>产品/类型：人脸识别；</div>
    <div>使用目的：活体检测实现实人认证；</div>
    <div>
      使用场景：用户在使用第三方开发者应用中与人脸核验/验证相关的功能场景时
    </div>
    <div>共享信息名称：人脸视频及照片；</div>
    <div>共享方式：SDK集成；</div>
    <div>
      隐私政策地址：<a
        href="https://privacy.qq.com/document/preview/13a6cbc478814273a8584cce698b8e08"
        ><u
          >https://privacy.qq.com/document/preview/13a6cbc478814273a8584cce698b8e08</u
        ></a
      >。
    </div>

    <div style="margin-top: 20px">
      <strong>(7)腾讯图灵盾人脸防攻击 SDK；</strong>
    </div>
    <div>提供方的公司名称：深圳市腾讯计算机系统有限公司；</div>
    <div>产品/类型：安全风控；</div>
    <div>使用目的：实人认证安全风控；</div>
    <div>
      使用场景：用户在使用第三方开发者应用中与人脸核验/验证相关的功能场景时
    </div>
    <div>
      共享信息名称：1. 系统设置、系统属性、网络状态、设备型号、操作系统、IP
      地址、相机（相机参数、接口）；
    </div>
    <ol start="2">
      <li>
        不同系统版本还共享以下信息：Android：硬件序列号
        OAID、部分存储文件路径；iOS：运营商信息、iDFV；
      </li>
    </ol>
    <div>共享方式：SDK集成；</div>
    <div>
      隐私政策地址：<a
        href="https://privacy.qq.com/document/preview/b8f92d6cdc0d4dc3ab6192d7096cee68"
        ><u
          >https://privacy.qq.com/document/preview/b8f92d6cdc0d4dc3ab6192d7096cee68</u
        ></a
      >。
    </div>

    <div style="margin-top: 20px">
      <strong>(8)Bugly SDK（仅 Android 端使用）；</strong>
    </div>
    <div>提供方的公司名称：深圳市腾讯计算机系统有限公司；</div>
    <div>产品/类型：Crash 监控类；</div>
    <div>使用目的：本 SDK 运行时对发生的故障问题进行排查、分析、解决；</div>
    <div>
      使用场景：（1）用户在使用第三方开发者应用中与人脸核验/验证相关的功能场景时（2）定位并解决用户在使用本
      SDK 服务时遇到的问题；
    </div>
    <div>
      共享信息名称：Android 端：手机型号、手机品牌、 Android 系统版本、Android
      系统 API 等级、厂商系统版本、CPU 架构类型、设备是否
      root、磁盘空间占用大小、sdcard
      空间占用大小、内存空间占用大小、网络类型、应用当前正在运行的进程名和 PID；
    </div>
    <div>共享方式：SDK集成；</div>
    <div>
      隐私政策地址：<a
        href="https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56"
        ><u
          >https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56</u
        ></a
      >。
    </div>
    <p><strong>二、我们如何存储、保护个人信息</strong></p>
    <p>
      <strong>1</strong
      ><strong>、我们建立专门的管理制度、流程和组织以保障信息的安全。</strong
      ><strong>&nbsp;</strong
      ><strong
        >例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。</strong
      >
    </p>
    <p>
      <strong></strong
      >2、为保障您的信息安全，我们对收集、存储的用户个人信息及行为将严格保密，&ldquo;我们&rdquo;努力在现有的技术水平下采取各种合理必要的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。&ldquo;我们&rdquo;对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密政策，监控他们的操作情况等措施。
    </p>
    <p>
      3、&ldquo;我们&rdquo;会按现有技术提供相应的安全措施来保护您的信息，
      提供合理的安全保障，
      我们将在任何时候尽力做到使您的信息不被泄漏、毁损或丢失。
    </p>
    <p>
      4、您的信息存储于中国的服务器上，为了安全及备份的需要，&ldquo;我们&rdquo;可能将您的信息和资料储存到&ldquo;我们&rdquo;或其他合作公司的服务器上。
    </p>
    <p>
      5、我们会按照相关法律、法规、部门规章规定的期限，存储用户个人信息；法律、法规、部门规章没有明确规定的，按照行业规范及业务需要的期限进行保存。
    </p>
    <p>
      6、并请您理解并明白，由于存在的各种各样的恶意手段，即便我们尽力采取上述措施，您的信息仍有可能被泄漏、毁损或灭失。
    </p>
    <p>7、您应审慎选择通过第三方使用我们服务，并妥善保护好您的个人信息。</p>
    <p>
      <strong>8</strong
      ><strong>、在使用我们服务时，请妥善保管好您的用户名</strong
      ><strong>/</strong><strong>帐户</strong><strong>/</strong
      ><strong
        >账户、账号及其对应密码，以及您就特定途用设置的其他密码（例如支付密码），我们会通过您的账户及其密码来识别您的身份，接收您作出的指令。一旦您泄漏了账号及其密码，您可能会丢失您的信息，并可能产生对您不利的法律后果。如您怀疑或发现账号及其密码因任何原因已经或将受到泄漏时，您应该立即和我们取得联系，但在我们知悉此种情况和采取行动前，我们对此不负任何责任。</strong
      ><strong>&nbsp;</strong
      ><strong
        >我们及关联公司采用合理安全措施（包括管理、技术和物理方面安全措施）来保护您提供的信息，防止信息遭到未经授权的访问、公开披露、使用、修改、破坏或丢失。</strong
      >
    </p>
    <p>
      9、尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。您知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码等个人信息透露给他人。
    </p>
    <p>
      10、我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，
      努力阻止该等安全事件的影响和后果扩大。一旦不幸发生信息安全事件（泄露、丢失等）后，
      我们将按照法律法规的要求，采取合理、有效的方式（如以推送通知、短信等形式告知您，
      难以逐一告知时，我们会采取合理、有效的方式发布公告）及时向您告知：
      安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、
      您可自主防范和降低风险的建议、对您的补救措施等。
      同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。
    </p>
    <p>
      11、我们谨此特别提醒您，本政策提供的个人信息保护措施仅适用于&ldquo;我们&rdquo;提供的相应服务，
      一旦您离开我们的服务范围，浏览或使用其他网站、服务及内容资源，
      我们将无法保护您在使用我们服务之外的软件、网站提交的任何个人信息，
      无论您登录、浏览。
    </p>
    <p>三、您的权利</p>
    <p>
      我们非常重视您对个人信息的管理，并尽全力保护您对于您个人信息的访问、
      修改（更新或更正）、删除以及撤回授权同意的权利，
      以使您拥有充分的能力保障您的隐私和安全。
    </p>
    <p>（一）管理、撤回授权您的信息</p>
    <p>
      您还有权撤回对您个人信息使用的同意，您可以通过删除信息、
      或者其他类似功能和服务中授权公司继续收集个人信息的范围或撤回您的授权。
    </p>
    <p>
      请您理解，特定的业务功能和服务将需要您的信息才能得以完成，
      当您撤回同意或授权后，公司无法继续为您提供撤回同意或授权所对应的功能和服务，
      也不再处理您相应的个人信息。但您撤回同意或授权的决定，
      不会影响公司此前基于您的授权而开展的个人信息处理。
    </p>
    <p>（二）投诉举报</p>
    <p>
      您可以按照我们公示的制度进行投诉或举报。
      如果您认为您的个人信息权利可能受到侵害，
      或者发现侵害个人信息权利的线索，您可以通过客服电话与我们联系。
    </p>
    <p>（三）访问隐私政策</p>
    <p>您可以在注册页面查看本隐私政策的全部内容。</p>
    <p>（四）停止运营向您告知权利</p>
    <p>
      当我们的产品或服务发生停止运营的情形时，我们将以推送通知、
      公告等形式通知您，并在合理的期限内删除您的个人信息或进行去标识化化处理。
    </p>
    <p><strong>四、我们如何共享、转让、公开披露个人信息</strong></p>
    <p><strong>（一） 个人信息的共享、转让</strong></p>
    <p>
      <strong
        >我们不会主动向第三方共享、转让您的个人信息，除非我们直接或确认第三方征得了您的事先授权同意，或者共享、转让的个人信息是去标识化处理后的信息，且共享第三方无法通过此类信息识别到您的身份。</strong
      >
    </p>
    <p><strong>1、在下列情况下，我们可能会以上述方式共享个人信息：</strong></p>
    <p>
      <strong
        >(1)
        用户身份验证、客户服务、安全防范、欺诈监测、存档和备份以及其他安全保障性用途，</strong
      >确保我们向您提供的产品和服务的安全性；如果我们监测到您使用我们的服务用于欺诈或非法目的，我们将会采取相应措施停止向您提供服务；我们不会将我们存储在分析软件中的信息与您在应用程序中提供的个人身份信息相结合。
    </p>
    <p>
      <strong
        >(2)
        开展审计，协助执行法律法规，响应或履行公安部门等监管机构的监管要求，以及遵守适用法律法规项下或向相关监管机构承诺的义务；</strong
      >
    </p>
    <p>
      <strong
        >(3)
        如我们使用您的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，我们将在使用您的个人信息前，再次向您告知并征得您的同意。</strong
      >
    </p>
    <p><strong>2、对共享个人信息第三方主体的谨慎评估及责任约束</strong></p>
    <p>
      (1) 经您同意，我们只会与第三方共享实现目的所必要的信息。<strong
        >如果第三方因业务需要，确需超出前述授权范围使用个人信息的，该第三方将需再次征求您的自主选择同意。</strong
      >
    </p>
    <p>
      <strong
        >(2)
        对我们与之共享您个人信息的第三方，该些第三方会与我们签订保密协议。同时，我们会对其数据安全能力与环境进行评估并要求第三方以不低于本隐私政策所要求的保密和安全措施来处理该些信息。</strong
      >
    </p>
    <p><strong>3、收购、兼并、重组时个人信息的转让</strong></p>
    <p>
      随着我们业务的持续发展，我们有可能进行合并、&nbsp;收购、资产转让等交易，您的个人信息有可能因此而被转移。&nbsp;在发生前述变更时，&nbsp;<strong
        >我们将按照法律法规及不低于本指引所要求的标准继续保护或要求新的控制者继续保护您的个人信息，否则我们将要求继受方重新征得您的授权同意。</strong
      >
    </p>
    <p><strong>（二） 个人信息的公开披露</strong></p>
    <p>
      <strong
        >目前，我们不会主动共享或转让您的个人信息至第三方，
        如存在其他共享或转让您的个人信息或您需要我们将您的个人信息共享或转让至第三方情形时，
        我们会直接或确认第三方征得您对上述行为的明示同意。</strong
      >
    </p>
    <p>
      <strong
        >我们不会对外公开披露其收集的个人信息，如必须公开披露时，
        我们会向您告知此次公开披露的目的、
        披露信息的类型及可能涉及的敏感信息，并征得您的明示同意。</strong
      >
    </p>
    <p>
      <strong>（三） 依法豁免征得同意共享、转让、公开披露的个人信息</strong>
    </p>
    <p>
      <strong
        >请您理解，根据相关法律法规及国家标准，以下情形中，我们可能会共享、转让、公开披露个人信息无需事先征得个人信息主体的授权同意：</strong
      >
    </p>
    <p><strong>(1)与国家安全、国防安全直接相关的；</strong></p>
    <p><strong>(2)与公共安全、公共卫生、重大公共利益直接相关的；</strong></p>
    <p><strong>(3)与犯罪侦查、起诉、审判和判决执行等直接相关的；</strong></p>
    <p>
      <strong
        >(4)
        出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</strong
      >
    </p>
    <p><strong>(5)个人信息主体自行向社会公众公开的个人信息；</strong></p>
    <p>
      <strong
        >(6)
        从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</strong
      >
    </p>
    <p><strong>五、未成年人保护</strong></p>
    <p>
      <strong>1</strong><strong>、</strong
      ><strong
        >&ldquo;我们&rdquo;仅向具备完全民事行为能力主体提供服务，如您为无民事行为能力人或为限制民事行为能力的未成年人，请告知您的监护人，同时，请您不要向&ldquo;我们&rdquo;提供任何个人信息，也不要使用任何服务。一经发现，我们有权拒绝提供全部服务，注销相关账户，也不会留存您提供的任何信息。</strong
      >
    </p>
    <p>
      <strong>2</strong
      ><strong>、我们非常重视对未成年人信息的保护。如果您是未满</strong
      ><strong>&nbsp;18 </strong
      ><strong
        >周岁的未成年人，您应立即停止操作，在您父母或其他监护人监护、指导下停止、</strong
      ><strong>退出注册流程</strong><strong>。</strong><strong>&nbsp;</strong
      ><strong
        >若您无法顺利退出相关操作流程，请您或您的监护人及时联系通知我们，我们将提供必要的协助，对您的账户进行后台注销，并删除相关信息。</strong
      >
    </p>
    <p>
      <strong>3</strong
      ><strong
        >、我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、</strong
      ><strong>&nbsp;</strong
      ><strong
        >父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用、</strong
      ><strong>&nbsp;</strong><strong>共享或披露未成年人的个人信息；</strong
      ><strong>&nbsp;</strong
      ><strong
        >如果我们发现在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，</strong
      ><strong>&nbsp;</strong><strong>则会设法尽快删除相关信息。</strong>
    </p>
    <p>
      <strong>4、若您是未成年人的监护人，</strong><strong>&nbsp;</strong
      ><strong>当您对所监护的未成年人的个人信息有任何疑问时，</strong
      ><strong>&nbsp;</strong
      ><strong>请通过本政策公示的联系方式联系我们。</strong>
    </p>
    <p><strong>六、隐私政策的修订和通知</strong></p>
    <p>
      <strong>1</strong
      ><strong>、随着我们服务的不断提升，本政策的内容也可能会不时更新，</strong
      ><strong>&nbsp;</strong
      ><strong
        >该等修订构成本隐私政策的一部分并具有等同于本隐私政策的效力。</strong
      ><strong>&nbsp;</strong><strong>但未经您明确同意，</strong
      ><strong>&nbsp;</strong
      ><strong>我们不会削减您依据当前生效的本隐私政策所应享受的权利。</strong>
    </p>
    <p>
      <strong></strong><strong>2</strong
      ><strong>、本政策更新后，我们会在服务平台发出更新版本，</strong
      ><strong>&nbsp;</strong
      ><strong>并在更新后的条款生效前以适当的方式提醒您更新的内容，</strong
      ><strong>&nbsp;</strong><strong>以便您及时了解本政策的最新版本。</strong
      ><strong>&nbsp;</strong
      ><strong
        >我们鼓励您时常登陆、查看服务页面以了解我们最新的隐私政策。</strong
      >
    </p>
    <p>
      <strong></strong><strong>3</strong
      ><strong
        >、若您在我们公布变更后的本政策后继续使用我们所提供的服务，</strong
      ><strong>&nbsp;</strong
      ><strong
        >则表示您已充分阅读、理解并接受经该等修订后的本政策的全部内容，</strong
      ><strong>&nbsp;</strong><strong>也将遵循该等内容使用本政策；</strong
      ><strong>&nbsp;</strong
      ><strong>若您不同意该等经修订后的本政策的任何内容，</strong
      ><strong>&nbsp;</strong><strong>您即应选择停止使用相应服务。</strong
      ><strong>&nbsp;</strong
      ><strong>但如果更新的服务内容需要采集您的地理位置、</strong
      ><strong>&nbsp;</strong><strong>联系方式、身份信息等个人敏感信息，</strong
      ><strong>&nbsp;</strong><strong>仍会再次以显著方式征求您的同意。</strong>
    </p>
    <p>
      <strong></strong><strong>4</strong
      ><strong>、对于重大变更，我们还会提供更为显著的通知</strong
      ><strong>&nbsp;</strong><strong>（我们会通过包括但不限于短信、</strong
      ><strong>&nbsp;</strong
      ><strong
        >私信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。</strong
      >
    </p>
    <p>
      <strong></strong><strong>5</strong
      ><strong>、本隐私政策所指的重大变更包括但不限于：</strong>
    </p>
    <p>
      <strong></strong><strong>(1) </strong
      ><strong
        >我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息的类型、个人信息的使用方式等；</strong
      >
    </p>
    <p>
      <strong></strong><strong>(2) </strong
      ><strong
        >我们在公司结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有变更等；</strong
      >
    </p>
    <p>
      <strong></strong><strong>(3)</strong
      ><strong>个人信息共享、转让或公开披露的主要对象发生变化；</strong>
    </p>
    <p>
      <strong></strong><strong>(4)</strong
      ><strong>您参与个人信息处理方面的权利及其行使方式发生重大变化；</strong>
    </p>
    <p>
      <strong></strong><strong>(5)</strong
      ><strong
        >我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时。</strong
      >
    </p>
    <p><strong>七、对第三方责任的声明</strong></p>
    <p>
      <strong>1</strong
      ><strong
        >、请您注意，您的交易相对方、您访问的第三方网站经营者、您使用的第三方服务提供者和由</strong
      ><strong>&ldquo;</strong><strong>我们</strong><strong>&rdquo;</strong
      ><strong
        >处接受您的个人信息的第三方可能有自己的隐私权保护规则；当您查看第三方创建的网页或使用第三方开发的应用程序时，可能会发现该网页或应用程序放置的</strong
      ><strong>Cookie</strong
      ><strong>或像素标签。同样，这些第三方可能会放置他们自己的</strong
      ><strong>Cookie</strong><strong>或像素标签，这些</strong
      ><strong>Cookie</strong><strong>或标签不受</strong><strong>&ldquo;</strong
      ><strong>我们</strong><strong>&rdquo;</strong
      ><strong>的控制，而且它们的使用不受本政策的约束。</strong>
    </p>
    <p>
      <strong></strong><strong>2</strong><strong>、</strong
      ><strong>&ldquo;</strong><strong>我们</strong><strong>&rdquo;</strong
      ><strong
        >会尽商业上的合理努力去要求这些主体对您的个人信息采取保护措施，但</strong
      ><strong>&ldquo;</strong><strong>我们</strong><strong>&rdquo;</strong
      ><strong>无法保证这些主体一定会按照</strong><strong>&ldquo;</strong
      ><strong>我们</strong><strong>&rdquo;</strong
      ><strong
        >的要求采取保护措施，亦不对这些主体的行为及后果承担任何责任。如果您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。</strong
      >
    </p>
    <p><strong>八</strong><strong>、适用范围</strong></p>
    <p>
      本政策适用于您访问&ldquo;我们&rdquo;网站及其相关网站、&ldquo;我们&rdquo;移动设备客户端、POS终端、微信公众号和&ldquo;我们&rdquo;提供的任一服务。
    </p>
    <p><strong>九</strong><strong>、如何与我们联系</strong></p>
    <p>
      我们已经成立个人信息保护组并配有个人信息保护专门负责人员，如您对本政策存在任何疑问，或对于您的个人信息处理存在任何投诉、意见，请通过以下渠道联系我们：
    </p>
    <p>1、在线客服：官方公众号</p>
    <p>2、联动优势客服/个人信息保护相关负责人联系电话：致电至400-156-8886</p>
    <p>3、联动优势客服中心联系地址：北京市朝阳区光华路7号汉威大厦西区20层</p>
    <p>
      您可随时登录您的&ldquo;我们&rdquo;账户查询并管理该账户下您的个人信息，如您遇到任何障碍或疑问，可联系&ldquo;我们&rdquo;客服【400-156-8886】。如您不满意我们的答复，<strong
        >有权将争议提交至北京仲裁委员会按照其现行有效的仲裁规则在北京进行仲裁。</strong
      >
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.content-box {
  // width: 100%;
  margin: 30px;
  padding-bottom: 30px;
  word-break: break-all;
  table {
    border-collapse: collapse;
    width: 100%;
  }
}
</style>
